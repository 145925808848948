body {
  min-width: auto; }
.body--mob-scroll-disabled {
  @include r(999) {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0; } }
.layout__header,
.layout__container {
 }  //overflow: hidden
.layout__container {
  @include r(999) {
    margin-top: 0; } }
.page__content,
.catalogMenu__categoriesWrapper {
  max-width: $xl-container;
  width: 100%;
  @include r(1199) {
    max-width: $lg-container;
    padding: 0; }
  @include r(999) {
    max-width: $md-container; }
  @include r(767) {
    max-width: 420px;
    padding: 0; } }
.pageHeader {
  @include r(767) {
    padding-top: 0; } }
.pageHeader__holder {
  @include r(767) {
    min-height: 140px; } }
.pageHeader__title {
  @include r(767) {
    font-size: 24px; } }
.phone__rootFooter .phone__number a {
  color: #ffffff; }
.product__imageLink {
  max-width: 240px;
  width: auto;
  height: auto; }
* {
  @include r(1199) {
    box-sizing: border-box; } }

.w1200 {
  @include r(1199) {
    width: auto!important; }
  //+r(1199)
 }  //  width: $lg-container!important

.phone__number {
  a {
    color: inherit; } }
.below-lg {
  @media all and (min-width: $lg + 1px) {
    display: none; } }
.below-md {
  @media all and (min-width: $md + 1px) {
    display: none; } }
.below-sm {
  @media all and (min-width: $sm + 1px) {
    display: none; } }

button:focus {
  outline: none; }
