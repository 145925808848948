.categoriesPage__itemImage {
  position: static; }
.categoriesPage__itemImageHolder {
  max-width: 170px;
  width: 100%;
  height: auto; }
.categoriesPage__item {
  @include r(1199) {
    padding: 20px 15px; } }
.categoriesPage__itemTitle {
  @include r(1199) {
    font-size: 16px; } }
.catalogPage__content2 {
  .grid__item20 {
    @include r(999) {
      width: 25%; }
    @include r(767) {
      width: 50%; } } }
.guaranties__item {
  @include r(999) {
    flex-direction: column; }
  @include r(767) {
    width: 50%;
    flex: unset; } }
.guaranties__text {
  @include r(999) {
    text-align: center; } }
.guaranties__list {
  @include r(767) {
    flex-wrap: wrap; } }
.guaranties {
  @include r(767) {
    padding: 0 0 30px; } }
