.customContent,
.customContent__root {
  @include r(767) {
    padding: 0 15px; }
  img {
    margin: 15px auto;
    @include r(767) {
      margin: 15px -15px;
      max-width: calc(100% + 30px); } }
  a {
    color: #e5007e; }
  ul li + li {
    margin-top: 8px; }
  h2 {
    @include r(1199) {
      margin: 30px 0 15px; }
    @include r(999) {
      margin: 25px 0 15px; }
    @include r(767) {
      font-size: 20px;
      text-align: left;
      margin: 10px 0; } }
  h3 {
    @include r(999) {
      margin: 20px 0 15px; }
    @include r(767) {
      font-size: 18px;
      margin: 10px 0; } }
  p + p {
    @include r(767) {
      margin-top: 10px; } }
  ul {
    @include r(767) {
      margin: 10px 0; }
    li {
      @include r(767) {
        padding-left: 15px;
        &:before {
          left: 0; } } } }
  tr {
    @include r(767) {
      display: block; }
    td {
      @include r(767) {
        display: block;
        padding-left: 0; } } } }
img.customContent__messenger {
  margin: 0 10px;
  @include r(767) {
    margin: 0 5px; } }
.libraryPostPage__quoteIconHolder {
  @include r(767) {
    width: 40px;
    height: 40px; }
  &:after {
    content: '”';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 70px;
    transform: rotate(180deg);
    @include r(767) {
      font-size: 50px; } } }
.libraryPostPage__quoteIconHolder + .libraryPostPage__quoteIconHolder {
  &:after {
    transform: rotate(0deg); } }

.libraryPostPage__quote {
  @include r(1199) {
    margin: 35px 0;
    font-size: 28px; }
  @include r(999) {
    padding: 40px 20px;
    font-size: 24px; }
  @include r(767) {
    padding: 30px 15px;
    margin: 30px -15px;
    font-size: 18px; } }
.libraryPostPage__title {
  @include r(767) {
    font-size: 22px; } }
.libraryPostPage__breadcrumbs {
  @include r(767) {
    margin-bottom: 15px; } }
.libraryPostPage__topInfo {
  @include r(767) {
    margin-bottom: 15px; } }

.libraryPostPage__commentsHead {
  @include r(767) {
    display: block;
    text-align: center; } }
.libraryPostPage__bottomNavigation {
  @include r(767) {
    display: block;
    text-align: center;
    padding-top: 20px;
    margin-top: 20px;
    .share__root {
      display: block; } } }
.libraryPostPage__bottom {
  @include r(767) {
    padding: 20px 0; } }
.comments__body {
  @include r(767) {
    font-size: 16px;
    margin: 10px 0; } }
.comments__list {
  @include r(767) {
    padding: 0 15px; } }
.comments__name {
  @include r(767) {
    font-size: 16px; } }
.comments__addHolder {
  @include r(767) {
    padding: 20px 15px 0; } }
.comments__mainButton {
  @include r(767) {
    margin-right: 0;
    font-size: 16px; }
  .button__caption {
    @include r(767) {
      font-size: 16px; } } }
.share__title {
  @include r(767) {
    margin-right: 0;
    margin-bottom: 5px; } }

