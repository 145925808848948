.libraryPage__content {
  margin-top: -66px; }

.libraryPage__wrapper {
  .library__item {
    display: block; }
  .library__itemW2 {
    &:not(:first-child) {
      @include r(999) {
        width: 33.333%; }
      @include r(767) {
        width: 50%; } } } }
.libraryPage__tabs {
  @include r(767) {
    display: block; } }
.libraryPage__tabItemActive {
  .libraryPage__tabLink:after {
    @include r(767) {
      border-top-color: #ffffff; } }
  &:first-child {
    .libraryPage__tabLink:after {
      @include r(767) {
        top: unset;
        bottom: 0;
        border-top-color: transparent;
        border-bottom-color: #ffffff;
        border-bottom: 10px solid; } } } }
.libraryPage__tabLink {
  @include r(767) {
    height: 50px;
    font-size: 16px; } }
.libraryPage__tagItem {
  @include r(767) {
    margin-top: 5px;
    margin-left: 5px; } }
