// cart 1
.cartList__product {
  @include r(1199) {
    padding: 20px 30px; }
  @include r(999) {
    padding: 15px; }
  @include r(767) {
    flex-wrap: wrap;
    align-items: flex-start; } }
.cartList__panel {
  @include r(1199) {
    padding: 30px; }
  @include r(999) {
    padding: 30px 15px; }
  @include r(767) {
    padding: 15px;
    padding-bottom: 0;
    flex-wrap: wrap; } }
.cartList__info {
  @include r(1199) {
    width: 390px; }
  @include r(767) {
    width: 65%;
    margin-left: auto;
    padding-right: 0;
    margin-bottom: 10px; } }
.cartList__additionalItem {
  @include r(767) {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid #c9c9c9;
    margin-bottom: 5px;
    &:last-child {
      border-right: none;
      margin-right: 0;
      padding-right: 0; } } }
.cartList__additionalItem + .cartList__additionalItem {
  @include r(767) {
    padding-left: 0;
    margin-left: 0;
    border-left: none; } }
.cartList__totalPrice {
  @include r(1199) {
    width: 130px; }
  @include r(999) {
    padding-right: 10px;
    font-size: 15px;
    width: 100px; }
  @include r(767) {
    align-self: center;
    margin: 0 auto;
    font-size: 18px; } }
.cartList__priceHolder {
  @include r(1199) {
    width: 130px; }
  @include r(999) {
    padding-right: 15px;
    width: 100px; }
  @include r(767) {
    display: none; } }
.cartList__discountedPrice {
  @include r(999) {
    font-size: 14px; } }
.cartList__delete {
  @include r(999) {
    width: 30px;
    height: 30px; } }
.cartList__counterHolder {
  @include r(999) {
    width: 115px;
    padding-right: 15px;
    height: 35px; } }
.cartList__additionalInfo {
  @include r(999) {
    font-size: 14px; }
  @include r(767) {
    font-size: 12px;
    margin-bottom: 15px; } }
.cartList__productTitle {
  @include r(999) {
    font-size: 16px; } }
.cartList__pageTotalValue {
  @include r(999) {
    padding-right: 0;
    width: auto; } }
.cartList__pageControls {
  @include r(999) {
    padding: 30px 0; }
  @include r(767) {
    padding: 15px;
    .button {
      width: 100%; } } }
.colCounter__input {
  @include r(999) {
    font-size: 16px; } }
.cartList__imageHolder {
  @include r(767) {
    width: 32%;
    margin-right: 3%;
    height: auto;
    img {
      height: auto;
      max-height: unset;
      position: static; } } }
.cartList__panelInput {
  @include r(767) {
    width: 72%; } }
.cartList__panelCaption {
  @include r(767) {
    width: 28%;
    margin-right: 0;
    position: relative;
    z-index: 1; } }
.cartList__panelImage {
  @include r(767) {
    top: 0;
    left: 0;
    transform: none;
    width: 28%; } }
.cartList__input {
  @include r(767) {
    font-size: 16px; } }
.cartList__inputButton {
  @include r(767) {
    font-size: 12px; } }
.cartList__pageTotal {
  @include r(767) {
    background: #fff;
    margin: 0 -15px;
    margin-top: 15px;
    width: calc(100% + 30px);
    position: relative;
    z-index: 2;
    padding: 15px; } }
.cartList__buttonContent {
  @include r(767) {
    width: auto; } }
.cartList__buttonText {
  @include r(767) {
    font-size: 16px; } }
.checkoutPage__stepItem {
  @include r(767) {
    font-size: 13px;
    text-align: center;
    span {
      max-width: 100px; }
    &:nth-child(3) {
      flex-grow: 1.2; } } }
.checkoutPage {
  .pageHeader__image {
    @include r(767) {
      height: 100%; } } }
.cartConfirmation__title {
  @include r(767) {
    font-size: 18px; } }
.no-items {
  @include r(767) {
    padding: 25px 0; } }
.cartPopup__button {
  color: #000;
  &.button__themeWhite {
    color: #ee007e; } }


// cart 2
.form-group {
  box-sizing: border-box; }
#field_customer_passwd, #field_customer_conf_passwd, #field_delivery_city, #field_delivery_street, #field_delivery_id_country, #field_delivery_id_state {
  width: 50%;
  @include r(767) {
    width: 100%; } }
#field_customer_firstname, #field_customer_phone, #field_customer_lastname {
  width: 33.333%;
  @include r(767) {
    width: 100%; } }
.StreetName {
  width: 100%;
  padding-right: 2%;
  box-sizing: border-box; }
.for_collapse7.area_sity .input, .ukrposhta.area_sity .input {
  width: 50%;
  padding-right: 2%;
  @include r(767) {
    width: 100%;
    margin-bottom: 10px; } }
.delivery-option .area_delivery, .city_delivery {
  box-sizing: border-box;
  width: 50%;
  padding-right: 2%;
  @include r(767) {
    width: 100%; } }
.delivery-option .area_sity {
  @include r(767) {
    flex-wrap: wrap; } }
.area_delivery {
  @include r(767) {
    margin-bottom: 10px; } }
.ware_delivery {
  box-sizing: border-box;
  width: 100%;
  padding-right: 2%; }
.cartForm__total {
  padding-top: 60px;
  padding-bottom: 60px;
  height: auto;
  @include r(1199) {
    padding-left: 35px;
    padding-right: 35px;
    justify-content: center; }
  @include r(767) {
    padding: 30px 15px; } }
.cartForm__totalButton {
  @include r(767) {
    br {
      display: none; } } }
.cartForm__totalContent {
  @include r(767) {
    display: block; } }
#payment_method_container, #shipping_container {
  @include r(767) {
    padding-right: 15px;
    padding-left: 15px; } }
.delivery-option .extra-content {
  margin-right: -2%;
  @include r(767) {
    padding-bottom: 0; } }
#onepagecheckoutps #form_customer, #onepagecheckoutps #delivery_address_container .fields_container {
  margin-right: -2%; }
.cartForm__caption {
  @include r(767) {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 15px;
    flex-wrap: wrap; } }
#customer_container {
  @include r(767) {
    padding-left: 15px;
    padding-right: 15px; } }
.checkoutPage__stepsIcon {
  @include r(767) {
    display: none; } }
button#opc_show_login {
  @include r(767) {
    position: static;
    width: 100%;
    text-align: left; } }
.cartForm__block,
.cartForm__total {
  @include r(1199) {
    width: 100%!important; } }
.delivery-option {
  @include r(1199) {
    display: block; } }
#payment_method_container .payment_content, #shipping_container .delivery_content {
  @include r(767) {
    padding: 10px 0;
    font-size: 18px; } }
#payment_method_container .payment_input, #shipping_container .delivery-input {
  @include r(767) {
    margin-top: 13px; } }
div#onepagecheckoutps_step_two_container {
  @include r(1199) {
    order: 2; }
  //width: 45%
 }  //box-sizing: border-box
div#onepagecheckoutps_step_three_container {
  @include r(1199) {
    order: 3; }
  //order: 3
  //width: 55%
  //border-right: 1px solid #f0f4f6
  //box-sizing: border-box
 }  //border-top: 1px solid #f0f4f6
//div#onepagecheckoutps_step_one_container
//  width: 55%
//  box-sizing: border-box
div#onepagecheckoutps_step_review_container {
  @include r(999) {
    padding: 35px 50px; }
  @include r(767) {
    padding: 25px 15px; } }
//  width: 45%
//  box-sizing: border-box
//cart 3
.cartConfirmation {
  @include r(767) {
    min-height: 400px;
    padding: 0 15px; } }
.cartConfirmation__info {
  @include r(767) {
    font-size: 28px; } }
.cartConfirmation__text {
  @include r(767) {
    font-size: 20px; } }

.cartForm {
  @include r(999) {
    display: block; } }
.cartForm__form:nth-child(3) {
  @include r(999) {
    width: 100%; } }
.cartForm__totalContent {
  @include r(999) {
    display: block; } }
.cartForm__totalText {
  @include r(999) {
    display: flex;
    align-content: center;
    margin: 0 0 15px; }
  @include r(767) {
    justify-content: center; } }
.cartForm__totalCaption {
  @include r(999) {
    text-align: right;
    margin-right: 15px; } }
.cartForm__totalButton {
  @include r(999) {
    br {
      display: none; } } }
.cartForm__totalPrice {
  @include r(999) {
    margin: 0; } }
#deliveri-carrier {
  @include r(767) {
    padding-left: 15px;
    padding-right: 15px; } }
.cartForm__addressCaption {
  @include r(767) {
    padding-left: 15px;
    padding-right: 15px; } }
.cartForm__totalButtonHolder {
  @include r(767) {
    width: 100%;
    display: block; } }
#onepagecheckoutps_step_review {
  @include r(767) {
    width: 100%; } }
