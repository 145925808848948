.userSettings__form {
  @include r(999) {
    padding: 30px; }
  @include r(767) {
    padding: 20px 15px; } }
.userSettings__block {
  @include r(767) {
    padding: 20px 15px; } }
.userSettings__column {
  @include r(999) {
    padding-right: 20px;
    padding-left: 20px; }
  @include r(767) {
    padding-right: 0;
    padding-left: 0;
    &:first-child {
      margin-bottom: 20px; } } }
.userSettings__columns {
  @include r(767) {
    display: block; } }

.userSettings__formRow {
  @include r(767) {
    display: block;
    margin-left: 0px; } }
.userSettings__formItem180,
.userSettings__formItem {
  @include r(767) {
    width: 100%;
    margin-bottom: 20px; } }
.userSettings__formRow + .userSettings__formRow {
  @include r(767) {
    margin-top: 0; } }
.userSettings__formSection {
  @include r(767) {
    display: block;
    padding-left: 0; } }
.userSettings__footer {
  @include r(767) {
    display: block; } }
.userSettings__footerItem {
  @include r(767) {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0; } } }
.userSettings__formItem220 {
  @include r(767) {
    width: 100%;
    margin-bottom: 20px; } }
.userSettings__address {
  @include r(767) {
    display: block; } }
.userPage__wrapper {
  .tabs {
    @include r(767) {
      height: 50px; } }
  .tabs__tabLabel {
    @include r(767) {
      font-size: 14px; } } }
.userPage {
  .pageHeader {
    @include r(767) {
      padding-bottom: 15px; } }
  .pageHeader__userLink {
    @include r(767) {
      font-size: 14px; } }
  .pageHeader__userLeftControl {
    @include r(767) {
      margin-right: 10px; } }
  .pageHeader__userRightControl {
    @include r(767) {
      margin-left: 10px; } }
  .pageHeader__user {
    @include r(767) {
      margin-bottom: 10px; } } }

.userFavorite {
  .grid__item25 {
    @include r(1199) {
      width: 33.33%; }
    @include r(767) {
      width: 50%;
      .toFavorite.active .toFavorite__iconFill {
        display: none; }
      .toFavorite__iconCross {
        display: block; } } } }

