.callMePopup {
  @include r(999) {
    padding: 40px 30px; }
  @include r(767) {
    max-width: 300px;
    width: auto;
    padding: 30px 15px; } }
.adultConfirm {
  @include r(999) {
    max-width: 490px;
    width: 100%;
    padding: 40px 30px; } }
.adultConfirm__imageHolder {
  @include r(767) {
    display: block; } }

.cartPopup {
  max-width: 600px;
  width: 100%;
  @include r(767) {
    padding: 30px 15px; } }
.cartPopup__title {
  @include r(767) {
    font-size: 22px; } }
.cartPopup__text {
  @include r(767) {
    font-size: 20px;
    margin-top: 15px; } }
.cartPopup__buttonsHolder {
  @include r(767) {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .button {
      width: 48%;
      margin: 0; }
    .button__caption {
      font-size: 14px; } }
  .cartPopup__button {
    background: #eeeeee;
    -webkit-appearance: unset;
    border-color: #dde7ec; } }

#modal-login {
  .overlay__inner {
    overflow: auto;
    @include r(767) {
      display: block; } }
  .overlay__close {
    @include r(999) {
      right: 0;
      top: -15px; }
    @include r(767) {
      top: 5px;
      right: 5px; } } }
.header__loginForm {
  @include r(999) {
    max-width: 960px;
    width: 100%; } }
.loginForm__columnContent {
  @include r(999) {
    max-width: 360px;
    width: 100%; } }
.loginForm__rememberLink {
  a {
    color: inherit; } }
.loginForm__column {
  @include r(767) {
    padding: 15px; } }
.loginForm {
  @include r(767) {
    display: block; } }
