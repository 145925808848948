.staticMenu__link {
  @include r(1199) {
    font-size: 16px;
    padding-left: 30px;
    padding-right: 30px; }
  @include r(999) {
    font-size: 14px;
    padding-left: 20px;
    padding-right: 20px; }
  @include r(767) {
    padding: 0; } }
.staticPage__aside {
  @include r(1199) {
    width: 23%; }
  @include r(767) {
    width: 100%; } }
.staticMenu__icon {
  @include r(1199) {
    right: 15px; }
  @include r(999) {
    right: 10px; }
  @include r(767) {
    display: none; } }
.staticMenu__linkActive {
  background: #ffffff;
  @include r(767) {
    background: transparent; } }
.staticPage__main {
  @include r(1199) {
    padding: 30px; }
  @include r(767) {
    padding: 15px 0; } }
.staticPage__content {
  @include r(767) {
    display: block; } }
.staticMenu__item {
  @include r(767) {
    display: inline-block;
    border: none;
    margin: 0 15px 10px 0; } }
.staticMenu__item + .staticMenu__item {
  @include r(767) {
    border: none; } }
.staticMenu__list {
  @include r(767) {
    padding: 15px;
    line-height: 1;
    padding-bottom: 5px; } }
