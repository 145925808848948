.catalogPage__aside {
  width: 25%;
  @include r(999) {
    position: fixed;
    top: 0;
    width: 250px;
    background: transparent;
    z-index: 999;
    padding-right: 62px;
    box-sizing: border-box;
    border-right: none;
    display: none; }
  @include r(767) {
    max-width: 420px;
    width: 100%;
    padding-right: 50px; }
  &--open {
    @include r(999) {
      display: block;
      .search_filters_module__underlayer {
        position: fixed;
        z-index: -1;
        background: rgba(0, 0, 0, 0.8);
        left: 0;
        top: 0;
        right: 0;
        bottom: 0; } } } }

.filter__item {
  @include r(1199) {
    padding: 15px; } }
.catalogPage__filterHeader {
  @include r(1199) {
    padding: 0 15px;
    font-size: 16px; } }
.catalogPage__filterClear {
  @include r(1199) {
    right: 15px; } }
html [type=button].catalogPage__filterClear {
  @include r(999) {
    -webkit-appearance: unset; } }
.catalogPage__sortInner {
  flex-wrap: wrap;
  @include r(1199) {
    font-size: 16px; } }
.catalogPage__wrapper {
  .checkbox {
    @include r(1199) {
      font-size: 16px; } } }
.filter__scrollItem + .filter__scrollItem {
  @include r(1199) {
    margin-top: 5px; } }
.bannerConsultation__buttonHolder {
  @include r(1199) {
    left: 0px;
    right: 0px; }
  .button__fullWidth {
    @include r(1199) {
      padding: 5px; } } }
.bannerConsultation__content {
  @include r(1199) {
    left: 15px;
    right: 15px; } }
.bannerConsultation {
  @include r(999) {
    display: none; } }
#search_filters_wrapper {
  @include r(999) {
    overflow: scroll;
    height: 100vh;
    padding-bottom: 120px; } }
.catalogPage__filterClearHelper {
  @include r(999) {
    display: none; } }
.catalogPage__filterHeader {
  @include r(999) {
    position: absolute;
    left: 0;
    right: 62px;
    top: 0;
    //width: 100%
    z-index: 10; }
  @include r(767) {
    right: 50px;
    height: 50px; } }
.catalogPage__filter {
  @include r(999) {
    padding-top: 66px;
    background: #ffffff; }
  @include r(767) {
    padding-top: 50px; } }
.catalogPage__aside_close-button {
  display: none;
  @include r(999) {
    display: block;
    width: 62px;
    height: 66px;
    position: absolute;
    right: 0;
    top: 0;
    border: none;
    padding: 10px;
    background: #e5007e;
    .icon {
      fill: #fff; } }
  @include r(767) {
    width: 50px;
    height: 50px; } }
.catalogPage__sortItem {
  @include r(1199) {
    padding: 5px 15px; }
  &:first-child {
    display: none; }
  @include r(999) {
    display: flex;
    align-items: center;
    //justify-content: center
    &:nth-child(3) {
      display: none; }
    &:first-child {
      display: flex; }
    .catalogPage__filterHeader {
      position: static;
      height: 40px;
      width: 100%;
      background: #f0f4f6;
      color: #000000; } }
  @include r(767) {
    padding: 0;
    width: 50%;
    background: #f0f4f6;
    &:last-child {
      display: none; }
    &:nth-child(2) {
      .catalogPage__sortInner {
        display: block;
        > span {
          display: block;
          margin-left: 10px; } } }
    .catalogPage__filterHeader {
      height: 100%; }
    .catalogPage__filterIcon {
      width: 18px;
      height: 18px;
 }      //margin-right: 10px
    .catalogPage__filterTitle {
 } } }      //font-size: 13px
.bannerDelivery {
  @include r(767) {
    height: 340px; } }
.bannerDelivery__content {
  @include r(767) {
    right: 15px;
    top: 15px;
    left: 15px; } }
.catalogPage__main {
  @include r(767) {
    .grid__item33 {
      width: 50%; } } }
.active_filters {
  @include r(999) {
    padding: 10px 15px;
    ul {
      margin-bottom: -.625rem; } }
  @include r(767) {
    margin-bottom: 15px; } }
.active_filters .filter-block {
  @include r(767) {
    padding: 7px;
    padding-right: 21px;
    position: relative;
    .js-search-link {
      position: absolute;
      right: 3px;
      top: 50%;
      transform: translateY(-50%); } } }
.active_filters .filter-block .close {
  @include r(767) {
    margin: 0; } }
#search_filter_controls {
  @include r(767) {
    display: none; } }
.catalogPage__filter {
  @include r(767) {
    .title {
      display: none; }
    .filter__scrollList {
      max-height: 350px;
      overflow: auto; } } }
.filter__priceRange {
  @include r(767) {
    margin: 20px 13px 10px; } }
#search_filter_controls {
  display: none; }
.filter__item {
  @include r(999) {
    &:first-child {
      .filter__scrollItem {
        margin-top: 0; } } } }
.js-filter-catalog-open,
.js-filter-catalog-close {
  cursor: pointer; }
.product__hoverHolder {
  @include r(999) {
    display: none; } }


