@mixin r($screen) {
  @media all and (max-width: $screen+'px') {
    @content; } }

@mixin mr($screen) {
  @media all and (min-width: $screen+'px') {
    @content; } }

@mixin b($screen) {
  @media all and (max-height: $screen+'px') {
    @content; } }

@function vw($target, $from:1920) {
  $vw-context: ($from*.01) * 1px;
  $vw-value: ($target/$vw-context);
  @return $vw-value * 1vw; }

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false ) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot');
      src: font-url('#{$file-path}.eot?#iefix') format('embedded-opentype'), font-url('#{$file-path}.ttf') format('truetype'); }
    @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'), url('#{$file-path}.ttf') format('truetype'); } } }


@mixin trs() {
  transition: all .3s ease-in-out; }
