.layout__footer {}

.subscribe__wrapper {
  @include r(999) {
    height: 235px; }
  @include r(767) {
    display: block;
    height: auto;
    margin-bottom: 60px;
    &:after {
      display: none; } } }
.subscribe__textHolder {
  @include r(1199) {
    padding-left: 15px;
    padding-right: 10px;
    max-width: 300px; }
  @include r(999) {
    max-width: 250px; }
  @include r(767) {
    max-width: none;
    padding: 20px 15px 15px;
    text-align: center; } }
.subscribe__heartHolder {
  @include r(767) {
    transform: translateX(-50%);
    margin-top: 10px; } }
.subscribe__title {
  @include r(767) {
    justify-content: center; } }
.subscribe__titleText {
  @include r(1199) {
    line-height: 1; }
  @include r(999) {
    font-size: 20px; }
  @include r(767) {
    font-size: 18px;
    flex: unset; } }
.subscribe__text {
  @include r(1199) {
    line-height: 1.2;
    margin-top: 20px; }
  @include r(999) {
    font-size: 18px; }
  @include r(767) {
    font-size: 16px; } }
.subscribe__titleImage {
  @include r(767) {
    width: 45px;
    margin-top: -10px;
    margin-right: 10px; } }
.subscribe__form {
  @include r(1199) {
    max-width: 345px;
    padding-right: 15px;
    &:before {
      width: 100%;
      left: 0;
      background-size: contain; } }
  @include r(999) {
    padding: 0;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      left: unset;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      @include r(767) {
        display: none; } } }
  @include r(767) {
    padding-right: 15px;
    padding-left: 15px;
    max-width: none; } }
.subscribe__inputHolder {
  @include r(1199) {
    max-width: 100%; } }
.subscribe__inputButton {
  @include r(1199) {
    font-size: 14px; } }
.subscribe__input {
  @include r(1199) {
    padding-right: 130px; } }
.subscribe__hands {
  @include r(999) {
    background-size: cover;
    height: 100%; }
  @include r(767) {
    display: none; } }
.subscribe__content:before {
  @include r(767) {
    right: 0;
    height: 100px; } }
.subscribe {
  @include r(767) {
    margin: -45px 0 -40px;
    background: #ffffff;
    .page__content {
 } } }      //max-width: none
// footer
.footer__copyright {
  @include r(1199) {
    max-width: 280px;
    padding-right: 50px; }
  @include r(999) {
    max-width: none;
    width: 100%;
    display: flex;
    padding-right: 0;
    > a {
      flex-shrink: 0;
      @include r(767) {
        display: none; } } }
  @include r(767) {
    text-align: center;
    padding: 0 15px; } }
.footer__copyrightText {
  @include r(999) {
    margin-top: 0; } }
.footer__logo {
  @include r(999) {
    width: 110px;
    margin-right: 30px; } }
.footer__black {
  @include r(767) {
    padding: 25px 0; } }
.footer__contacts {
  @include r(999) {
    padding: 0; }
  @include r(767) {
    display: block;
    margin-top: 0;
    text-align: center; } }
.footer__messangers {
  @include r(999) {
    order: 2;
    width: 155px;
    margin: 0 auto; }
  @include r(767) {
    width: 100%;
    border-bottom: 1px solid #272727;
    padding: 15px 0;
    .messangers {
      justify-content: center; } }
  .messangers__text {
    @include r(999) {
      display: none; }
    @include r(767) {
      display: block;
      max-width: 160px;
      text-align: left; } } }
.messangers__list {
  @include r(999) {
    margin-left: 0; } }
.footer__phone {
  width: auto;
  .phone {
 }    //display: block
  @include r(999) {
    width: 250px;
    order: 3; }
  @include r(767) {
    width: 100%;
    border-bottom: 1px solid #272727;
    padding: 15px 0; } }
.footer__mail {
  @include r(999) {
    order: 1;
    width: 240px; }
  @include r(767) {
    width: 100%;
    border-bottom: 1px solid #272727;
    padding: 15px 0; } }
.footer__mailLink {
  @include r(767) {
    font-size: 20px; } }
.phone__rootFooter .phone__number {
  @include r(999) {
    font-size: 26px; } }

.payment {
  @include r(999) {
    flex-direction: column;
    position: relative; } }
.payment__list {
  @include r(999) {
    width: 100%;
    padding-right: 160px; } }
.payment__text {
  @include r(999) {
    padding-right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 20px; } }
.payment__more {
  @include r(999) {
    //width: 100%
    position: absolute;
    right: 0;
    bottom: 0; } }
.footer__payment {
  @include r(999) {
    padding-top: 25px;
    padding-bottom: 25px; }
  @include r(767) {
    display: none; } }
.footer__bottom {
  @include r(999) {
    margin-top: 0;
    flex-direction: column-reverse;
    padding-top: 40px; }
  @include r(767) {
    border-top: 0;
    padding: 0;
    padding-top: 15px; } }
.footer__navigation {
  @include r(999) {
    justify-content: space-between;
    margin-bottom: 20px; }
  @include r(767) {
    flex-wrap: wrap; } }
.footerNavigation__column {
  @include r(767) {
    width: 50%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 15px;
    &:first-child {
      margin-right: 0; }
    &:last-child {
      width: 100%;
      margin-right: 0;
      margin-bottom: 0; } } }
.footerNavigation__caption {
  @include r(767) {
    margin-bottom: 0; } }
.footerNavigation__columnBlock+.footerNavigation__columnBlock {
  @include r(767) {
    border-top: 1px solid #272727;
    border-bottom: 1px solid #272727;
    padding: 15px 0;
    margin-top: 15px;
    .footerNavigation__caption {
      display: none; }
    .social__link {
      width: 23px;
      height: 23px; } } }
.dirty_show_auth_popup {
 color: #e5007e; }
