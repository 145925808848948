.menu_transparent {
  max-width: 100%;
  @include r(999) {
    background: rgba(0,0,0,0.8); } }
.layout__header {
  .pm_m_close {
    width: 65px;
    height: 65px;
    background: #e5007e;
    position: absolute;
    right: 0;
    top: 0;
    //display: block
    //pointer-events: none
    border: none;
    padding: 5px;
    .icon {
      fill: white; }
    @include r(370) {
      width: 40px;
      height: 40px; } }
  #pm_menu .catalogMenu__panelSticky {
    @include r(1240) {
      right: 20px;
      left: 20px; }
    @include r(1199) {
      right: unset;
      left: unset;
      max-width: $lg-container; } }
  @include r(1199) {
    .logo {
      width: 200px;
      &:before {
        display: none; } }
    .logo__emuHolder {
      display: none;
      width: 80px;
      margin-bottom: -50px; }
    .logo__dirty {
      height: auto; }
    .phone__number {
      font-size: 23px; }
    .header__phone {
      width: 245px; }
    .header__consultation {
      padding-right: 30px;
      width: 250px; }
    .phone__call {
      padding-right: 5px; }
    #pm_menu .catalogMenu__menuList {
      padding: 0 15px; }
    .pm_m_brands_container > li {
      padding: 30px 20px; } }
  @include r(999) {
    .header__top,
    .logo:after,
    .header__phone {
      display: none; }
    .header__consultation {
      display: none; }
    .cart__empty {
      display: none; }
    .cart:before {
      display: none; }
    .cart__link {
      min-width: auto; }
    .cart__iconHolder {
      margin-right: 0; }
    .catalogMenu__panelMenu {
      display: none; }
    .header__bottomWrapper:after,
    .header__bottomWrapper:before {
      display: none; }
    .header__mainWrapper {
      justify-content: flex-end; }
    .header__bottomWrapper {
      background: transparent; }
    .search__formHolder {
      margin-top: -15px;
      left: -20px;
      right: unset; }
    .header__controlsItem {
      &:first-child {
        position: absolute;
        left: 78px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        @include r(767) {
          &.search--opened {
            z-index: 100; } } } }
    .cart {
      margin-left: 30px; }
    .cart__value {
      margin-left: 10px; }
    .logo {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding: 0;
      max-width: 100px; }
    .logo__dirtyHolder {
      margin-right: 0;
      display: flex;
      align-items: center; }
    .header__controlsItem + .header__controlsItem {
      margin-left: 30px; }
    .catalogMenu__panelWrapper {
      display: none; }
    .header__bottomWrapper {
      height: auto; }
    .header__main {
      padding-bottom: 0; }
    .header__main:after {
      height: 20px;
      background-position-y: bottom; }

    .catalogMenu__panelButton {
      width: auto;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%); }
    .catalogMenu__button {
      background: transparent;
      height: auto; }
    .catalogMenu__buttonIcon {
      background: white;
      &:after,
      &:before {
        background: white; } }
    .pm_m_subcategories_container {
      display: none; }
    .pm_m_cover {
      display: none; }
    #pm_menu .pm_m_main_categories_container, #pm_menu .pm_m_subcategories_container, #pm_menu .pm_m_cover, #pm_menu .pm_m_brands_container {
      width: 50%; }
    .catalogMenu__panel.w1200 {
      position: fixed;
      top: 0;
      max-width: 600px!important;
      padding-right: 65px;
      box-sizing: content-box;
      z-index: 2147483001!important;
      @include r(370) {
        padding-right: 40px; } }
    .pm_m_brands_menu {
      margin-bottom: -10px;
      li {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
        line-height: 1;
        &:last-child {
          margin-right: 0;
          margin-bottom: 0; } } }
    .pm_m_brands_menu-container {
      height: auto!important; }
    .pm_m_brands_container>li {
      height: auto!important; }
    .pm_m_brands_container {
      height: auto!important; }
    .pm_m_catalog_container {
      height: 100vh;
      overflow: auto;
      background: transparent;
      box-shadow: none;
      padding-bottom: 120px; }
    .pm_m_main_categories_container {
      background: white; }
    .pm_m_social {
      width: 50%;
      background: #f1f4f5;
      padding: 15px;
      display: flex;
      flex-direction: column-reverse;
      > * {
        height: auto!important; }
      .header__social {
        display: block; }
      .topMenu__list {
        display: block; }
      .topMenu__spacer {
        display: none; }
      .topMenu__item {
        display: block; }
      .header__navigation {
        width: 100%;
        display: block;
        border-bottom: 1px solid #e6e9ea;
        padding-bottom: 10px; }
      .header__social {
        padding-top: 10px; }
      .social {
        display: flex; }
      .social__link {
        width: auto;
        height: 20px; } }
    .pm_m_brands_container {
      > li {
        padding: 25px 15px; } }
    .pm_m_callback {
      width: 50%;
      background: #f1f4f5;
      padding: 15px;
      border-bottom: 1px solid #e6e9ea;
      .header__phone {
        display: block; }
      .phone__number span {
        color: #e5007e; }
      .phone__call {
        color: #e5007e; }
      .phone__time {
        color: #757b81; } }
    .pm_m_consult {
      width: 50%;
      background: #e5007e;
      padding: 15px;
      border-bottom: 6px solid #cb006f;
      .header__consultation {
        display: block; } }
    .pm_m_catalog_container {
      div {
        height: auto!important; } }
    .pm_m_main_categories_container {
      max-height: 565px; } }
  @include r(767) {
    #pm_menu .pm_m_main_categories_container li a {
      padding: 10px 15px; }
    .cart {
      margin-left: 0;
      padding: 12px;
      padding-right: 10px;
      @include r(350) {
        padding: 8px; } }
    .header__controlsItem + .header__controlsItem {
      margin-left: 0;
      padding: 12px;
      @include r(350) {
        padding: 8px; } }
    .logo {
      max-width: 70px; }
    .header__controlsItem:first-child {
 }      //left: 15px
    .search__formHolder {
      width: 260px;
      animation: unset; }
    .cart__value {
      display: none; }
    .catalogMenu__panel.w1200 {
      max-width: 380px !important; }
    #pm_menu .pm_m_main_categories_container, #pm_menu .pm_m_subcategories_container, #pm_menu .pm_m_cover, #pm_menu .pm_m_brands_container {
      width: 100%;
      float: unset; }
    .pm_m_brands_container {
      > li {
        display: none; }
      .pm_m_brands_menu-container {
        display: block; } }
    .pm_m_main_categories_container {
      max-height: 400px; }
    .pm_m_consult,
    .pm_m_social,
    .pm_m_callback {
      width: 100%; } } }

// phone
.phone {
  &__list {
    position: relative;
    border: 1px solid transparent;
    border-bottom: 0;
    &--opened {
      background: #e5007e;
      box-shadow: 0px 17px 46px -5px #000;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-color: darken(#e5007e, 10);
      @include r(999) {
        border: none;
        box-shadow: none;
        background: transparent; }
      .phone__items {
        display: block; } } } }
.phone__number {
  padding: 2px 20px 2px 5px;
  display: block; }
.phone__item {
  border-top: 1px solid darken(#e5007e, 10);
  //font-size: 21px
  @include r(999) {
    border: none; }
  &:hover {
    background: darken(#e5007e, 5);
    @include r(999) {
      border: none;
      background: transparent; } } }
.phone__info {
  padding: 0 5px; }
.phone__items {
  overflow: hidden;
  display: none;
  position: absolute;
  z-index: 10;
  left: -1px;
  width: calc(100%);
  top: 100%;
  background: #e5007e;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: 0px 20px 25px -10px #000;
  border: 1px solid darken(#e5007e, 10);
  border-top: 0;
  @include r(1199) {
    width: calc(100% + 2px); }
  @include r(999) {
    border: none;
    display: block;
    position: static;
    box-shadow: none;
    background: transparent; } }
.header__phone {
  //width: 320px
  padding-right: 0px; }
.phone__button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: block;
  width: 20px;
  cursor: pointer;
  &:hover {
    .phone__button__icon {
      fill: #000000; } }
  @include r(999) {
    display: none; }
  &__icon {
    transition: all 0.2s;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    width: 7px;
    fill: #ffffff; } }

.footer__phone {
  .phone__list--opened {
    background: #101010;
    border-color: lighten(#101010, 10);
    @include r(999) {
      border: none; } }
  .phone__items {
    background: #101010;
    border-color: lighten(#101010, 10);
    @include r(999) {
      border: none; } }
  .phone__item {
    border-top: 1px solid lighten(#101010, 10);
    color: darken(#ffffff, 50);
    //font-size: 27px
    @include r(999) {
      border: none; }
    &.phone__number a {
      color: darken(#ffffff, 50); }
    &:hover {
      background: #000000;
      color: #e5007e;
      @include r(999) {
        background: transparent;
        color: darken(#ffffff, 50); }
      &.phone__number a {
        color: #e5007e;
        @include r(999) {
          color: darken(#ffffff, 50); } }
      span {
        color: #e5007e;
        @include r(999) {
          color: darken(#ffffff, 50); } } }
    span {
      color: darken(#ffffff, 50); } }
  .phone__button {
    &:hover {
      .phone__button__icon {
        fill: #e5007e; } } } }

