.pageHeader__root {
  overflow: hidden;
  @include r(999) {
    min-height: 460px; }
  @include r(767) {
    min-height: auto; }
  .page__content {
    @include r(767) {
      max-width: 320px; }
 } }    //display:
.pageHeader__childrenSingle {
  @include r(999) {
 }    //padding-top: 20px
  @include r(999) {
    padding: 20px 0; } }
.topCategories {
  @include r(767) {
    flex-direction: column-reverse;
    margin: -7px; } }
.topCategories__side {
  @include r(1199) {
    width: 142px;
    .topCategories__image {
      max-width: 100%; } }
  @include r(999) {
    width: 110px;
    margin-right: 13px; }
  @include r(767) {
    width: 100%;
    display: flex;
    flex-direction: row; } }
.topCategories__sideItem+.topCategories__sideItem {
  @include r(999) {
    margin-top: 13px; }
  @include r(767) {
    margin-top: 0; } }
.topCategories__styleItem+.topCategories__styleItem {
  @include r(999) {
    margin-left: 13px; }
  @include r(767) {
    margin-left: 0; } }
.topCategories__sideItem {
  @include r(1199) {
    height: 121px; }
  @include r(999) {
    height: 94px; }
  @include r(767) {
    width: 33.333%;
    height: 100px;
    .topCategories__labelText {
      font-size: 12px; } } }
.topCategories__linkHit {
  .topCategories__image {
    @include r(1199) {
      width: 150px;
      max-width: none; }
    @include r(999) {
      width: 116px; }
    @include r(767) {
      width: 105%; } }
  .topCategories__label:before {
    @include r(1199) {
      height: 55px; }
    @include r(999) {
      height: 42px; }
    @include r(767) {
      height: 37px; } } }
.topCategories__linkNew {
  .topCategories__label:before {
    @include r(1199) {
      height: 43px; }
    @include r(999) {
      height: 33px; }
    @include r(767) {
      height: 30px; } } }

.topCategories__linkDiscount {
  .topCategories__label:before {
    @include r(1199) {
      height: 42px; }
    @include r(999) {
      height: 32px; }
    @include r(767) {
      height: 29px; } } }
.topCategories__linkEurope {
  .topCategories__image {
    @include r(1199) {
      height: 281px;
      width: 100%; }
    @include r(999) {
      height: 215px; } }
  .topCategories__label:before {
    @include r(1199) {
      height: 65px; }
    @include r(999) {
      height: 51px; }
    @include r(767) {
      height: 53px; } } }
.topCategories__linkUSA {
  .topCategories__image {
    @include r(1199) {
      height: 282px; }
    @include r(999) {
      height: 217px;
      width: 110%; } }
  .topCategories__label:before {
    @include r(1199) {
      height: 57px; }
    @include r(999) {
      height: 45px; }
    @include r(767) {
      height: 47px; } } }
.topCategories__linkJapane {
  .topCategories__image {
    @include r(1199) {
      height: 278px;
      width: 100%; }
    @include r(999) {
      height: 214px; } }
  .topCategories__label:before {
    @include r(1199) {
      height: 44px; }
    @include r(999) {
      height: 34px; }
    @include r(767) {
      height: 36px; } } }
.topCategories__linkBDSM {
  .topCategories__image {
    @include r(1199) {
      height: 289px; }
    @include r(999) {
      height: 216px;
      bottom: 0; } }
  .topCategories__label:before {
    @include r(1199) {
      height: 48px; }
    @include r(999) {
      height: 38px; }
    @include r(767) {
      height: 40px; } } }
.topCategories__linkEconomy {
  .topCategories__image {
    @include r(1199) {
      height: 141px;
      width: auto;
      bottom: -5px; }
    @include r(999) {
      height: 110px;
      bottom: -4px;
      left: -3px;
      width: 103%; } }
  .topCategories__label:before {
    @include r(1199) {
      background-size: auto; }
    @include r(999) {
      background-size: 56%;
      height: 36px; }
    @include r(767) {
      height: 38px; } } }
.topCategories__linkPremium {
  .topCategories__image {
    @include r(1199) {
      height: 129px;
      width: auto; }
    @include r(999) {
      height: 100px; }
    @include r(767) {
      height: auto;
      width: 100%; } }
  .topCategories__label:before {
    @include r(1199) {
      background-size: auto; }
    @include r(999) {
      background-size: 54%;
      height: 33px; }
    @include r(767) {
      height: 35px; } } }

.topCategories__label {
  @include r(1199) {
    padding: 12px 15px;
    font-size: 16px; }
  @include r(999) {
    padding: 8px 10px;
    font-size: 14px; } }
.topCategories__label:before {
  @include r(1199) {
    background-size: 100%; } }

.topCategories__style {
  @include r(1199) {
    height: 121 * 2 + 20 + px; }
  @include r(999) {
    height: 94 * 2 + 13 + px; }
  @include r(767) {
    height: auto;
    flex-wrap: wrap; } }

.topCategories__styleItem {
  @include r(767) {
    flex: unset;
    height: 220px;
    width: 50%;
    //padding: 7px
    margin: 0;
 } }    //padding-bottom: 135%
.topCategories__link {
  @include r(767) {
    top: 7px;
    left: 7px;
    bottom: 7px;
    right: 7px; } }

.topCategories__level {
  @include r(1199) {
    height: 121px; }
  @include r(999) {
    margin-top: 13px;
    height: 94px; }
  @include r(767) {
    height: auto;
    display: block;
    margin-top: 0; } }
.topCategories__levelItem {
  @include r(767) {
    height: 110px; } }
.topCategories__levelItem+.topCategories__levelItem {
  @include r(999) {
    margin-left: 13px; }
  @include r(767) {
   margin-left: 0; } }
// products
.product__topInfo {
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    display: block;
    background: linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    width: 20px;
    height: 100%; } }
.product__inner {
  @include r(1199) {
    padding: 25px 15px; }
  @include r(767) {
    padding: 15px; } }
.product__toFavorite {
  @include r(1199) {
    right: 15px;
    top: 25px; }
  @include r(767) {
    top: 17px;
    z-index: 4;
    right: 17px; } }
.product__imageDiscountPercentages {
  @include r(767) {
    top: 0; } }
.bannerHit {
  @include r(1199) {
    padding: 25px 15px; }
  @include r(767) {
    padding: 17px 10px;
    background-position-y: top; } }
.bannerHit__topText {
  @include r(1199) {
    font-size: 24px; }
  @include r(767) {
    font-size: 18px; } }
.bannerHit__bottomText {
  @include r(1199) {
    font-size: 20px; }
  @include r(767) {
    font-size: 12px; } }
.cartButton__text {
  @include r(1199) {
    font-size: 13px; } }
.cartButton__icon {
  @include r(1199) {
    width: 20px;
    height: 20px; } }
.cartButton__mark {
  @include r(1199) {
    width: 13px;
    height: 13px;
    right: -4px;
    top: -6px; } }
.cartButton__plusIcon,
.cartButton__markIcon {
  @include r(1199) {
    width: 9px;
    height: 9px; } }
.cartButton3::before,
.preorderButton::before {
  @include r(1199) {
    width: 11px;
    height: 11px;
    left: 71%;
    border-radius: 1px; } }
.cartButton__typeProduct2 {
  @include r(1199) {
    width: 28%; } }
.cartButton3 {
  @include r(1199) {
    width: 72%; } }

.mainProducts {
  .grid__item {
    @include r(999) {
      &:first-child {
        width: 66.666%; }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 33.333%; }
      &:nth-child(5),
      &:nth-child(6) {
        display: none; }
      &:last-child {
        display: block;
        width: 33.333%; } }
    @include r(767) {
      &:first-child {
        width: 100%; }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:last-child {
        width: 50%; } } }
  .grid__item25 {
    @include r(767) {
      .product__topInfo {
        display: none; } } }
  .product__hoverHolder {
    @include r(767) {
      display: none; } }
  .page__content {
    @include r(767) {
      position: relative; } } }

.mainProducts__tabs {
  @include r(767) {
    margin-top: 0;
    position: static;
    height: auto;
    display: block; } }
.product__dailyTitle {
  @include r(767) {
    font-size: 30px; } }
.mainProducts__tabItem.active {
  //+r(767)
  //  //position: absolute
  //  z-index: 4
  //  width: 100%
  //  height: 50px
  //  & ~ .mainProducts__tabItem:nth-child(2)
  //    bottom: 70px
  //  .mainProducts__tabLabel:after
  //    border-bottom-color: #e4017d
  //    transform: translateX(-50%) rotate(180deg)
 }  //    bottom: -10px

.mainProducts__tabItem:not(.active) {
  //+r(767)
  //  position: absolute
  //  left: 0
  //  bottom: 20px
  //  width: 100%
  //  height: 50px
  //  box-shadow: none
  //  label
  //    background: #f1f4f5
  //    border-bottom: 1px solid #f1f4f5
  //    height: 49px
  //    color: #e4017d
  //  &:first-child
 }  //    bottom: 70px

.product__discountPrice {
  @include r(767) {
    font-size: 13px; } }
.product__price {
  @include r(767) {
    font-size: 11px; } }
.product__dailyImage {
  @include r(1199) {
    max-width: 200px;
    width: 100%; }
  @include r(767) {
    width: 50%; } }
.product__dailyPrice {
  @include r(1199) {
    flex: unset;
    flex-shrink: 0;
    max-width: 80px;
    padding-top: 5px; }
  @include r(767) {
    width: 100%;
    text-align: right;
    max-width: none;
    margin: 0 auto;
    padding: 0 15px;
    padding-top: 25px; } }
.product__dayliPrice {
  @include r(1199) {
    font-size: 35px; }
  @include r(767) {
    font-size: 24px; } }
.product__dailyInfo {
  @include r(767) {
    flex-wrap: wrap;
    margin-bottom: 0; } }
.product__dailyTimer {
  @include r(1199) {
    max-width: 180px;
    flex: unset; }
  @include r(767) {
    width: 50%;
    margin-right: 0; } }
.more {
  padding-top: 60px;
  @include r(1199) {
    height: 120px; }
  @include r(999) {
    padding-top: 0;
    height: 60px; }
  @include r(767) {
    height: 50px; } }


// sliders
.slide1__image {
  z-index: -1;
  height: 100%;
  bottom: unset;
  top: 50%;
  transform: translateY(-50%);
  right: -10%;
  @include r(1199) {
    right: -25%;
    height: 90%; } }
.slide1__inner:before {
  z-index: -1;
  display: none; }
.slider__pages {
  position: static;
  margin-top: 40px;
  @include r(767) {
    margin-top: 30px; } }
.mainConsultation__image {
  z-index: -1;
  @include r(1199) {
    right: -275px; } }
.mainConsultation {
  &:before,
  &:after {
    z-index: -1; } }

@include r(999) {
  .mainSlider__page {
    display: block; }
  .mainSlider__sliderHolder {
    min-height: 500px;
    @include r(767) {
      min-height: 450px; } }
  .mainSlider__consultationHolder {
    position: relative;
    min-height: 500px;
    @include r(767) {
      min-height: 450px; } }
  .mainConsultation__inner {
    position: static; }
  .mainConsultation__content {
    padding: 80px 0 90px;
    @include r(767) {
      max-width: 180px; } }
  .mainConsultation__buttonHolder {
    position: static; }
  .mainConsultation__image {
    top: unset;
    bottom: -20px;
    right: -75px;
    max-width: 480px;
    width: 100%;
    height: auto;
    @include r(767) {
      width: 450px;
      right: unset;
      left: 25%; } }
  .mainConsultation__title {
    font-size: 36px;
    @include r(767) {
      font-size: 22px; } }
  .mainSlider__wrapper:after {
    display: none; }
  .slider__list {
    overflow: visible;
    @include r(767) {
      height: 530px; } }
  .carousel {
    box-shadow: none; }
  .mainConsultation:after {
    left: 100%;
    top: 27px;
    bottom: 0;
    transform: rotate(90deg);
    transform-origin: right top; }
  .slide1__image {
    right: 0;
    height: 100%;
    @include r(767) {
      height: 60%;
      right: -25%; } }
  .slide1__inner {
    overflow: hidden;
    @include r(767) {
      overflow: visible; }
    //position: relative
    &:before {
      right: 0; }
    &:after {
      content: '';
      width: 40px;
      height: 100%;
      right: -20px;
      top: 0;
      background: url("../images/design/slider_divider.png") no-repeat top center;
      position: absolute;
      @include r(767) {
        display: none; } } }
  .slide1__title {
    font-size: 36px;
    @include r(767) {
      font-size: 22px; } } }

@include r(767) {
  .slide1__inner {
    overflow: visible;
    //position: relative
    &:before {
      display: none; } }
  .mainSlider__sliderHolder {
    .hidden-sm-down {
      display: block!important; } }
  .slide1__sale {
    max-width: 120px;
    height: auto; }
  .slide1__text {
    font-size: 18px;
    margin: 18px 0;
    max-width: 180px; }
  .slide1__content {
    max-width: 200px;
    img {
      height: auto; } }
  .slides__buttonLink {
    width: 280px; }
  .mainConsultation__text {
    font-size: 18px;
    margin: 18px 0;
    max-width: 180px; }
  .mainSlider__wrapper {
    padding: 0 15px; } }

// library
.mainLibrary {
  @include r(1199) {
    padding: 50px 0; }
  @include r(767) {
    padding: 15px 0 70px; } }
.library__item {
  width: 25%;
  @include r(1199) {
    height: (485 / 2) + px; }
  @include r(999) {
    width: 33.333%;
    &:nth-child(n+6) {
      display: none; } }
  @include r(767) {
    height: 160px;
    width: 50%; } }
.library__itemH2 {
  @include r(1199) {
    height: 485px; }
  @include r(999) {
    height: (485 / 2) + px; }
  @include r(767) {
    height: 160px; } }
.library__itemW2 {
  width: 50%;
  @include r(999) {
    width: 66.666%; }
  @include r(767) {
    width: 100%; } }
.library__itemTitle {
  @include r(1199) {
    font-size: 20px; }
  @include r(767) {
    font-size: 14px; } }
.library__content {
  @include r(767) {
    padding: 15px; } }
.library__category {
  @include r(767) {
    font-size: 14px;
    margin-bottom: 5px; } }
.library__statsItem {
  @include r(767) {
    font-size: 12px;
    & + .library__statsItem {
      margin-left: 20px; } } }
.mainLibrary__more {
  @include r(767) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; } }
.mainLibrary__title {
  @include r(767) {
    font-size: 22px;
    margin: 0 auto; } }
.mainLibrary__header {
  @include r(767) {
    margin-bottom: 15px; } }

// brands
.mainBrands__item {
  @include r(1199) {
    &:last-child {
      flex-shrink: 0;
      @include r(767) {
        white-space: nowrap;
        padding: 0;
        margin-left: 0;
        margin-right: 40px; } }
    &:first-child {
      margin-left: 0; } }
  @include r(999) {
    margin-left: 10px; }
  @include r(767) {
    max-width: 25%;
    margin: 10px auto 0;
    padding: 0 10px; } }
.mainBrands__list {
  @include r(1199) {
    display: flex;
    align-items: center;
    margin-left: 0; }
  @include r(767) {
    flex-wrap: wrap; } }
.mainBrands__allLink {
  @include r(999) {
    font-size: 16px; } }
.mainBrands__title {
  @include r(999) {
    font-size: 26px; }
  @include r(767) {
    font-size: 18px;
    padding: 0 18px; } }
.mainBrands__wrapper {
  @include r(999) {
    padding: 30px 0; }
  @include r(767) {
    padding: 10px 0; } }

// regions
.mainRegions {
  @include r(767) {
    position: relative;
    background: white;
    .page__content {
      max-width: unset; } } }
.mainRegions__listHolder {
  @include r(767) {
    padding-left: 0;
    position: relative;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background: #e4e8ea URL("../images/design/regions_bg.png") repeat center top;
      display: block;
      position: absolute;
      left: 0;
      top: 0; }
    &:before {
      width: 450px;
      top: -150px;
      left: 50%;
      transform: translateX(-50%);
      background-size: contain; } } }
.mainRegions__list {
  @include r(767) {
    padding: 10px 15px 30px;
    max-width: $sm-container;
    margin: 0 auto;
    text-align: center; } }
.mainRegions__wrapper {
  @include r(767) {
    margin-top: 120px;
    padding: 0;
    position: static;
    &:before {
      left: 50%;
      transform: translateX(-50%);
      top: -80px; }
    &:after {
      display: none; } } }
.mainRegions__item {
  @include r(767) {
    margin: 0 10px 0; } }

.search__autocompleteItem {
  @include r(767) {
    height: auto; } }
.search__autocompleteLink {
  @include r(767) {
    padding: 5px 15px;
    display: inline-block; } }
