.alphabetPage__rowItem {
  @include r(999) {
    display: block; }
  @include r(767) {
    padding: 15px 0; } }
.alphabetPage__rowSymbolHolder {
  @include r(999) {
    text-align: left;
    width: auto; }
  @include r(767) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 16px; } }
.alphabetPage__listHolder {
  @include r(767) {
    padding-left: 15px;
    padding-right: 15px; } }
.alphabetPage__list {
  @include r(767) {
    font-size: 16px;
    column-count: 2; } }
.alphabetPage__listItem + .alphabetPage__listItem {
  @include r(767) {
    margin-top: 0; } }
.alphabetPage__rowSymbol {
  @include r(767) {
    font-size: 18px; } }
.alphabetPage__symbolsItem {
  @include r(999) {
    padding: 0 6px; }
  @include r(767) {
    padding: 0 3px; } }
.alphabetPage__symbols {
  @include r(999) {
    height: auto;
    flex-wrap: wrap;
    justify-content: center; }
  @include r(767) {
    padding: 15px;
    font-size: 16px; } }

.alphabetPage {
  .tabs {
    @include r(767) {
      height: 45px;
      &__itemCurrent:after {
        border: 15px solid transparent;
        border-top: 5px solid #e5007e; } } }
  .tabs__tabLabel {
    @include r(767) {
      font-size: 14px;
      text-align: center; } } }
.alphabetPage__hintContent {
  @include r(767) {
    padding: 15px; } }
