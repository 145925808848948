.productMain__title {
  @include r(1199) {
    font-size: 30px; }
  @include r(999) {
    margin: 15px 0;
    font-size: 28px;
    padding: 0 25px; }
  @include r(767) {
    padding: 0;
    font-size: 22px; } }
.productMain__backLink {
  @include r(767) {
    display: block; } }
.productMain__backIcon {
  @include r(767) {
    margin-bottom: -2px; } }
#add-to-cart-or-refresh {
  .productMain__counter {
    @include r(1199) {
      width: 100px;
      height: 50px;
      margin-right: 10px; }
    @include r(999) {
      order: 1;
      width: 150px;
      height: 60px; } }
  .productMain__favorite {
    @include r(1199) {
      margin-left: 10px; }
    @include r(999) {
      order: 2; } }
  .cartButton__typeProduct {
    @include r(1199) {
      max-width: 228px;
      width: 100%;
      position: relative;
      &:before {
        position: absolute;
        left: 100%;
        margin: 0;
        margin-left: -8px;
        margin-top: -8px;
        top: 50%; } }
    @include r(767) {
      max-width: 300px; } }
  .cartButton__text {
    @include r(1199) {
      font-size: 12px; }
    @include r(999) {
      font-size: 14px; } }
  .cartButton__typeProduct2 {
    @include r(1199) {
      flex-shrink: 0; } }
  .productMain__cartButton {
    @include r(1199) {
      width: 248px;
      display: flex; }
    @include r(999) {
      width: 100%;
      margin-bottom: 25px; } }
  .productMain__selectorItem {
    @include r(999) {
      &:first-child {
        margin-top: 0; } }
    @include r(767) {
      //margin-left: 0
      margin-top: 15px; } }
  .productMain__controls {
    @include r(1199) {
      justify-content: space-between; }
    @include r(999) {
      flex-wrap: wrap; } }
  .productMain__advantagesItem + .productMain__advantagesItem {
    @include r(999) {
      margin-left: 0; } }
  .productMain__advantages {
    @include r(767) {
      display: block; } }
  .toFavorite__typeProduct {}
  .productMain__selectorList {
    @include r(999) {
      flex-wrap: wrap; }
    @include r(767) {
      justify-content: center; } }
  .productMain__selectorListItem {
    @include r(999) {
      margin: 0;
      margin-right: 7px;
      margin-bottom: 7px;
      &:last-child {
        margin-right: 0; } } }
  .productMain__selectorItem {
    @include r(999) {
      align-items: flex-start; }
    @include r(767) {
      display: block; } }
  .productMain__cartButton .preorderButton {
    @include r(1199) {
      width: 140px;
      position: relative;
      &:before {
        position: absolute;
        top: 50%!important;
        left: 100%!important;
        margin-top: -6px;
        margin-left: -6px; } }
    @include r(999) {
      width: 100%; } }

  .productMain__cartButton .preorderAlertButton {
    @include r(1199) {
      width: 110px; }
    @include r(999) {
      width: 100%; } } }
.productMain__priceLine {
  @include r(767) {
    align-items: center;
    justify-content: center;
    margin: 20px 0 15px; } }
.productMain__discountPrice {
  @include r(767) {
    font-size: 28px; } }
.productMain__price {
  @include r(767) {
    font-size: 20px; } }
.productMain__selectorCaption {
  @include r(767) {
    margin: 0;
    display: inline-block;
    margin-bottom: 8px; } }
.productMain__content {
  @include r(767) {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px; }
  .productMain__backHolder,
  .productMain__code,
  .productMain__title,
  .productMain__addInfo,
  .productMain__shortText {
    @include r(999) {
      display: none; } } }
.productMain__heading {
  @include r(999) {
    background: #f8fafb;
    padding: 35px 0 25px;
    text-align: center; }
  @include r(767) {
    padding: 35px 15px 20px; }
  &-info {
    @include r(999) {
      display: flex;
      justify-content: center; }
    @include r(767) {
      flex-wrap: wrap; } }
  &-container {
    @include r(999) {
      max-width: $md-container;
      margin: 0 auto; }
    @include r(767) {
      max-width: $sm-container; } } }
.productMain__backHolder {
  @include r(999) {
    margin-bottom: 15px; } }
.productMain {
  @include r(999) {
    padding-top: 0;
    background: #fff; }
  .page__content {
    @include r(999) {
      max-width: 100%; } } }
.productMain__wrapper {
  @include r(999) {
    max-width: $md-container;
    margin: 0 auto; }
  @include r(767) {
    max-width: $sm-container;
    display: block; } }
.productGallery {
  @include r(999) {
    min-height: 630px; }
  @include r(767) {
    min-height: 300px;
    height: 300px; } }
.productMain__galleryHolder {
  @include r(999) {
    width: 56%; }
  @include r(767) {
    width: 100%;
    padding-top: 24px;
    padding-left: 15px;
    padding-right: 15px; } }
.productGallery__mainHolder {
  @include r(767) {
    width: 100%;
    margin-left: 0; } }
.productGallery__imageDiscountPercentages {
  @include r(767) {
    top: 0;
    right: 10px; } }
.productDescription {
  @include r(767) {
    display: block;
    .customContent {
     font-size: 16px; }
    .customContent p + p {
      margin-top: 10px; } } }
.productDescription__description {
  @include r(999) {
    flex: unset;
    width: 56%; }
  @include r(767) {
    width: 100%;
    margin-bottom: 20px; } }
.productDescription__attributes {
  @include r(999) {
    margin-left: 30px; }
  @include r(767) {
    margin-left: 0; } }
.productDelivery {
  @include r(999) {
    display: block; } }
.productDelivery__column + .productDelivery__column {
  @include r(999) {
    margin-left: 0; } }
.productPage__tabsList {
  display: flex;
  @include r(767) {
    display: block; } }
.productPage__tabsItem {
  @include r(767) {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f8fafb;
    justify-content: center;
    font-size: 16px;
    height: 50px; } }
.productDescription__attributesCaption {
  @include r(999) {
    width: 110px;
    font-size: 14px;
    word-wrap: break-word; } }
.productDescription__attributesValue {
  @include r(999) {
    font-size: 16px; } }
.productPage__additionalTitle {
  @include r(999) {
    font-size: 21px; } }
.recomended__controls {
  @include r(999) {
    margin-bottom: 26px; } }
.benefit__banner:after {
  @include r(999) {
    width: 110px;
    height: 110px; } }
.benefit__bannerContent {
  @include r(1199) {
    padding: 15px; } }
.productMain__addInfo {
  @include r(999) {
    margin: 0;
    margin-right: 15px; } }
.productMain__code {
  @include r(999) {
    margin: 0; } }
.productPage__component {
  @include r(767) {
    padding: 25px 15px; } }
.productPage__additional {
  @include r(767) {
    display: none; } }
.productDelivery__deliveryItem {
  @include r(767) {
    display: block; } }
.productDelivery__deliveryCaption {
  @include r(767) {
    margin-right: 12px; } }
.productDescription__description p, .productMain__shortText p {
  @include r(767) {
    text-indent: 6px; } }
.productDescription__description .productMain__shortText {
  @include r(767) {
    font-size: 16px;
    margin-bottom: 10px; } }
.productDelivery__block {
  .customContent {
    @include r(767) {
      font-size: 16px; } } }
.customContent ol li:before {
  @include r(767) {
    font-size: 16px;
    top: 0; } }
.comments__productForm {
  @include r(767) {
    padding: 15px; } }
.comments__formHeader {
  @include r(767) {
    display: block; } }
.comments__formRating {
  @include r(767) {
    margin-bottom: 10px; } }
.productPage__tabsCounter {
  @include r(767) {
    width: 24px;
    height: 24px; } }
.productDescription__attributesItem {
  @include r(767) {
    display: block;
    span {
      display: block;
      width: 100%;
      max-width: 100%; } } }
.product__imageLink2 {
  max-width: 100%;
  width: auto;
  height: auto;
  &:before {
    content: '';
    display: inline-block;
    padding: 80% 0; } }

